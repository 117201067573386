
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';

import CatalogLogic from '../../../logic/CatalogLogic.js';
import CurriculumHeader from './CurriculumHeader.js';
import DeclarativeView from '../../catalog/DeclarativeView.js';
import NormalizedView from '../../catalog/NormalizedView.js';
import LanguageView from '../../catalog/LanguageView.js';
import ExperiencesView from './ExperiencesView.js';
import FormationsView from './FormationsView.js';

import './Curriculum.css';

const CurriculumView = ({ user, catalog, formations, experiences, showExpectedValues = false }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const { auth } = useAuthContext();

    const [categories, setCategories] = useState([]);
    const [showCategories, setShowCategories] = useState(false);
    const [showExperiences, setShowExperiences] = useState(false);
    const [showFormations, setShowFormations] = useState(false);

    /**
     * Page lifecycle
     */

    useEffect(() => {

        const categories = catalog?.children ?? [];
        setCategories(categories);

        const showCategories = categories.length > 0;
        setShowCategories(showCategories);

        const showExperiences = experiences.length > 0;
        setShowExperiences(showExperiences);

        const showFormations = formations.length > 0;
        setShowFormations(showFormations);

        // eslint-disable-next-line 
    }, [auth.lang, user]);

    return (

        <div className='container'>
            
            <CurriculumHeader className='mb-3 py-3' user={user} />
            
            <div className='row p-4'>

                {
                    !showCategories && !showFormations && !showFormations &&
                    <h3 className='m-5 p-5 text-center'>{t('mycv.nodata')}</h3>
                }

                {
                    showCategories &&
                    <div className='row'>
                        <ul className='nav nav-tabs'>
                            {
                                categories.map((node, i) => (
                                    <li key={i}>
                                        <button className={'nav-link' + (i === 0 ? ' active' : '')} data-bs-toggle='tab' data-bs-target={'#cvtab' + i}>
                                            {node.label}
                                        </button>
                                    </li>
                                ))
                            }
                            {
                                showExperiences &&
                                <li>
                                    <button className='nav-link' data-bs-toggle='tab' data-bs-target={'#cvtab' + categories.length}>
                                        {t('glossary.experiences')}
                                    </button>
                                </li>
                            }
                            {
                                showFormations &&
                                <li>
                                    <button className='nav-link' data-bs-toggle='tab' data-bs-target={'#cvtab' + categories.length + 1}>
                                        {t('glossary.formations')}
                                    </button>
                                </li>
                            }
                        </ul>

                        <div className='tab-content py-4'>

                            {
                                categories.map((category, i) => {

                                    const isDeclarative = CatalogLogic.isDeclarative(category);
                                    const isNormalized  = CatalogLogic.isNormalized(category);
                                    const isLanguage    = CatalogLogic.isLanguage(category);

                                    return (
                                        <div id={'cvtab' + i} key={i} className={'tab-pane fade' + (i === 0 ? ' show active' : '')}>
                                            {isDeclarative && <DeclarativeView category={category} showExpectedValues={showExpectedValues} />}
                                            {isNormalized  && <NormalizedView category={category} showExpectedValues={showExpectedValues} />}
                                            {isLanguage    && <LanguageView category={category} showExpectedValues={showExpectedValues} />}
                                        </div>
                                    )
                                })
                            }

                            <div id={'cvtab' + categories.length} className='tab-pane fade'>
                                <ExperiencesView experiences={experiences} />
                            </div>

                            <div id={'cvtab' + categories.length + 1} className='tab-pane fade'>
                                <FormationsView formations={formations} />
                            </div>

                        </div>
                    </div>
                }

            </div>
        </div >
    )
}

export default CurriculumView
