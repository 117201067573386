
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.js';
import { BrowserRouter as Router, } from 'react-router-dom';
import { AuthContext } from './controllers/useAuthContext.js';
import HttpInterceptor from './utils/HttpInterceptor.js';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './i18n.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

    <Router future={{ v7_relativeSplatPath: true, v7_startTransition: true }}>
        <AuthContext>
            <HttpInterceptor>
                <App />
            </HttpInterceptor>
        </AuthContext>
    </Router>

)
