
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import AppSettings from '../../../logic/AppSettings.js';
import DateUtil from '../../../utils/DateUtil.js';

const ExperienceBox = ({ experiences, ...props }) => {

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    
    const items = experiences.sort((e1, e2) => e2.start - e1.start).slice(0, 4);
    if (items.length < 1) return null;
    
    return (
        <div {...props} className='experience-box'>
            <div className='section-title'>{t('glossary.experience')}</div>
            {
                items.map((experience) => {

                    const startMonth = DateUtil.toShortMonthString(experience.start, auth.lang) ?? '-';
                    const startYear = new Date(experience.start).getFullYear();
                    const endMonth = DateUtil.toShortMonthString(experience.end, auth.lang) ?? t('mycv.today');
                    const endYear = experience.end ? new Date(experience.end).getFullYear() : '';
                    const description = experience.description?.trim().length > 0  ? experience.description : AppSettings.DUMMY_PARAGRAPH;

                    return (
                        <div key={experience.id} className='row pb-2'>
                            <div className='col-3 text-nowrap'>
                                <div><b><i>{experience.positionTitle}</i></b></div>
                                <div>{experience.company ?? '-'}</div>
                                <div className='text-muted'>{startMonth} {startYear} - {endMonth} {endYear}</div>
                            </div>
                            <div className='col'>
                                {description}
                            </div>


                        </div>
                    )
                })
            }

            <hr />
        </div>
    )
}

export default ExperienceBox