
import avatar_img from '../../../assets/images/avatar.svg';
import letter_img from '../../../assets/images/letter.svg';
import phone_img from '../../../assets/images/phone.svg';
import linkedin_img from '../../../assets/images/linkedin.svg';
import rocket_img from '../../../assets/images/rocket.svg';

import './UserBox.css'

const UserBox = ({ user, ...props }) => {

    return (

        <>
            <div {...props} className='user-box'>
                <div>
                    <img className='avatar' src={user.picture ?? avatar_img} alt='avatar' />
                </div>
                <div>
                    {user.firstname} {user.lastname}
                </div>
            </div>

            <div {...props} className='contact-box'>

                <div>
                    <img src={letter_img} alt='email' />
                    <span>{user.email ?? '-'}</span>
                </div>

                {
                    user.phone?.trim().length > 0 &&
                    <div>
                        <img src={phone_img} alt='phone' />
                        <span>{user.phone ?? '-'}</span>
                    </div>
                }

                {
                    user.linkedin?.trim().length > 0 &&
                    <div>
                        <img src={linkedin_img} alt='linkedin' />
                        <span>{user.linkedin ?? '-'}</span>
                    </div>
                }

                <div>
                    <img src={rocket_img} alt='availability' />
                    <span>{user.availability ?? 'n/a'}</span>
                </div>
                
            </div>
        </>
    )
}

export default UserBox