
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import CountryService from '../services/CountryService.js';
import LangUtil from "../utils/LangUtil.js";

const useCountryController = () => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();
    const [loaded, setLoaded] = useState(true);

    /**
     * Functionalities
     */

    const getCountries = async (filter = null) => {

        try {
            setLoaded(false);
            const data = await CountryService.getCountries(filter);
            data.items.forEach(item => translate(item, auth.lang));
            return data;
        }
        finally {
            setLoaded(true);
        }
    }

    const getCountry = async (id) => {
        if (id == null || id.trim().length === 0) return null;
        const country = await CountryService.getCountry(id);
        translate(country, auth.lang);
        return country;
    }

    const deletable = async (country) => {
        return country.id != null && await CountryService.isDeletable(country);
    }

    const create = async () => {
        const country = await CountryService.create();
        translate(country);
        return country;
    }

    const save = async (country) => {

        try {
            untranslate(country);
            await CountryService.save(country);
        }
        finally {
            translate(country);
        }
    }

    const remove = async (country) => {
        await CountryService.remove(country);
    }

    const translate = (country) => {
        LangUtil.translate(country, 'name', auth.lang);
    }

    const untranslate = (country) => {
        LangUtil.untranslate(country, 'name');
    }

    const retranslate = (countries) => {
        for (const country of countries) {
            untranslate(country);
            translate(country);
        }
    }

    return {
        loaded,
        getCountry,
        getCountries,
        deletable,
        create,
        save,
        remove,
        translate,
        untranslate,
        retranslate
    }
}

export default useCountryController;