
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';

import AppSettings from '../logic/AppSettings.js';
import StringUtil from '../utils/StringUtil.js';
import MotionService from '../services/MotionService.js';
import UserService from '../services/UserService.js';

import useUserController from '../controllers/useUserController.js';
import useCompanyController from '../controllers/useCompanyController.js';
import useCatalogController from '../controllers/useCatalogController.js';

const useMotionController = () => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();
    const [loaded, setLoaded] = useState(true);

    const userController = useUserController();
    const companyController = useCompanyController();
    const catalogController = useCatalogController();

    /**
     * Functionalities
     */

    const getMotions = async (filter) => {

        try {
            setLoaded(false);
            const data = await MotionService.getMotions(auth.company, filter);
            data.items.forEach(item => translate(item, auth.lang));
            return data;
        }
        finally {
            setLoaded(true);
        }
    }

    const getMotion = async (id) => {

        const motionId = id == null || id.trim().length === 0 || id === 'new' ? null : id;

        const motion = motionId
            ? await MotionService.getMotion(motionId)
            : await MotionService.create(auth.company, auth.user, auth.lang);

        motion.label = StringUtil.toFirstLetters(motion.name);
        motion.categories = motion.catalog.children;
        translate(motion);

        return motion;
    }

    const deletable = async (motion) => {
        return motion.id && await MotionService.isDeletable(motion);
    }

    const getMatchingsForMotion = async (motion, wishes, text = null) => {

        const matchings = motion.id &&
            (await MotionService.getMatchingsForMotion(motion?.id, wishes, AppSettings.PAGE_LIMIT, text))
                .map(user => {

                    user.distances = new Map(user.distances);
                    user.label = StringUtil.toFirstLetters(user.firstname + ' ' + user.lastname);
                    user.like = motion.likes.includes(user.id);

                    return user;
                })

        return matchings;
    }

    const getMatchingsForUser = async (user, wishes, limit = null, text = null) => {

        const matchings = user.id &&
            (await MotionService.getMatchingsForUser(user.id, wishes, limit ?? AppSettings.PAGE_LIMIT, text))
                .map(motion => {
                    motion.distances = new Map(motion.distances);
                    motion.label = StringUtil.toFirstLetters(motion.name);
                    motion.picture = motion.company.picture;
                    motion.like = motion.likes.includes(user.id);
                    return motion;
                })
                .sort((a, b) => {
                    return (a.distances).get('absolute') - b.distances.get('absolute');
                })

        return matchings;
    }

    const remove = async (motion) => {
        await MotionService.remove(motion);
    }

    const translate = (motion) => {
        motion.company && companyController.translate(motion.company);
        motion.owner && userController.translate(motion.owner);
        motion.catalog && catalogController.translate(motion.catalog);
    }

    const untranslate = (motion) => {
        motion.company && companyController.untranslate(motion.company);
        motion.owner && userController.untranslate(motion.owner);
        motion.catalog && catalogController.untranslate(motion.catalog);
    }

    const retranslate = (motions) => {
        for (const motion of motions) {
            untranslate(motion);
            translate(motion);
        }
    }

    const getMotionUser = async (userId) => {

        // Get user and initialize it
        const user = await UserService.getUser(userId);
        user.label = StringUtil.toFirstLetters(user.firstname + ' ' + user.lastname);

        // Get the company catalog
        const catalog = await catalogController.getCompanyCatalog(auth.user.company);

        // Get the skill's categories
        user.categories = catalog?.children ?? [];

        // Init default axes
        if (user.axes?.x === '' && user.categories.length > 0) user.axes.x = user.categories[0].id;
        if (user.axes?.y === '' && user.categories.length > 1) user.axes.y = user.categories[1].id;
        if (user.axes?.z === '' && user.categories.length > 2) user.axes.z = user.categories[2].id;

        return user;
    }

    const save = async (motion) => {

        try {
            untranslate(motion);
            motion.id = await MotionService.save(motion);
        }
        finally {
            translate(motion);
        }
    }

    const clone = async (motion) => {
        await MotionService.clone(motion);
    }

    const clone2snapshot = async (motion) => {
        await MotionService.clone2snapshot(motion);
    }

    const like = async (user, motion) => {

        motion.like = !motion.like;
        const motionClone = await MotionService.getMotion(motion.id);
        motionClone.like = motion.like;

        if (motion.like) {
            motionClone.likes.push(user.id);
        }
        else {
            motionClone.likes = motion.likes.filter(uid => uid !== user.id);
        }

        await save(motionClone);
    }

    return {
        loaded,
        getMotions,
        deletable,
        getMatchingsForMotion,
        getMatchingsForUser,
        remove,
        translate,
        untranslate,
        retranslate,
        getMotion,
        getMotionUser,
        save,
        clone,
        clone2snapshot,
        like
    }
}

export default useMotionController;