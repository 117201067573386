
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';

import './Legend.css'
import LevelBar from './LevelBar.js';

const Legend = ({ ...props }) => {

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const [legend, setLegend] = useState([]);

    useEffect(() => {

        const legend = t('mycv.legend').map(e => ({ ...e, level: +e.level })).sort((e1, e2) => e1.level - e2.level);
        setLegend(legend);

        // eslint-disable-next-line 
    }, [auth.lang]);

    return (

        <div className='key-box'>
            <div {...props} className='legend'>

                <div className='keys'>
                    <div>K</div>
                    <div>E</div>
                    <div>Y</div>
                    <div>S</div>
                </div>

                <div className='levels'>
                    <div>
                        <LevelBar level={5} label={legend[4]?.label} />
                        <LevelBar level={4} label={legend[3]?.label} />
                        <LevelBar level={3} label={legend[2]?.label} />
                    </div>
                    <div>
                        <LevelBar level={2} label={legend[1]?.label} />
                        <LevelBar level={1} label={legend[0]?.label} />
                    </div>
                </div>

            </div>

            <hr />

        </div>
    )
}

export default Legend