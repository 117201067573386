
import { useState } from 'react';
import { useAuthContext } from './useAuthContext.js';
import InvitationService from '../services/InvitationService.js';
import LangUtil from '../utils/LangUtil.js';
import ObjectUtil from '../utils/ObjectUtil.js';

const useInvitationController = () => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();
    const [loaded, setLoaded] = useState(true);

    /**
     * Functionalities
     */

    const getInvitations = async (filter) => {

        try {
            setLoaded(false);
            const data = await InvitationService.getInvitations(auth.user, filter);
            data.items.forEach(item => translate(item, auth.lang));
            return data;
        }
        finally {
            setLoaded(true);
        }
    }

    const getInvitation = async (id) => {

        if (id == null || id.trim().length === 0) return null;

        // Retrieve the invitation
        const invitation = await InvitationService.getInvitation(id, auth.lang);

        // Get all labels in current lang
        translate(invitation);

        return invitation;
    }

    const create = async (survey, user) => {
        return await InvitationService.create(survey, user);
    }

    const createInvitables = async (survey, filter) => {
        return await InvitationService.createInvitables(survey, auth.company, filter);
    }

    const update = async (invitation) => {
        const clone = ObjectUtil.clone(invitation);
        untranslate(clone);
        await InvitationService.update(clone);
    }

    const completed = async (invitation) => {
        await InvitationService.completed(invitation);
    }

    const translate = (invitation) => {
        invitation.catalog && LangUtil.translateTree(invitation.catalog, auth.lang);
    }

    const untranslate = (invitation) => {
        invitation.catalog && LangUtil.untranslateTree(invitation.catalog)
    }

    const retranslate = (invitations) => {
        for (const invitation of invitations) {
            untranslate(invitation);
            translate(invitation);
        }
    }

    return {
        loaded,
        getInvitations,
        getInvitation,
        create,
        createInvitables,
        update,
        completed,
        translate,
        untranslate,
        retranslate
    }
}

export default useInvitationController;