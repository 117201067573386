
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTreeContext } from './TreeContext.js';
import useModalEffect from '../../hooks/useModalEffect.js';
import TreeUtil from '../../utils/TreeUtil.js';
import CatalogLogic from '../../logic/CatalogLogic.js';

const PropertyDialog = ({ id, data, onSave }) => {

    /**
     * Component state
     */

    const { t } = useTranslation();
    const modalRef = useRef();

    const [node, setNode] = useState({});
    const treeContext = useTreeContext();

    const [isCategory, setCategory] = useState(false);
    const [isLeaf, setLeaf] = useState(false);
    const [isDeclarative, setDeclarative] = useState(false);
    const [isNormalized, setNormalized] = useState(false);
    const [isLanguage, setLanguage] = useState(false);
    const [isHobbies, setHobbies] = useState(false);
    const [isGeneralization, setGeneralization] = useState(false);
    const [isComposition, setComposition] = useState(false);

    /**
     * Component lifecycle
     */

    useModalEffect(modalRef, async () => {

        const node = TreeUtil.findById(data, treeContext.selectedId);
        
        // Define a category type if missing (TODO: to be removed after v3.1)
        const parent = TreeUtil.findParent(data, node.id);
        if (node.type == null  && parent.id === 'root') node.type = 'declarative';
        
        setNode({ ...node });
    })

    useEffect(() => {

            const isCategory = CatalogLogic.isCategory(node);
            const isLeaf = CatalogLogic.isLeaf(node);
            const isDeclarative = CatalogLogic.isDeclarative(node);
            const isNormalized = CatalogLogic.isNormalized(node);
            const isLanguage = CatalogLogic.isLanguage(node);
            const isHobbies = CatalogLogic.isHobbies(node);
            const isGeneralization = CatalogLogic.isGeneralization(node);
            const isComposition = CatalogLogic.isComposition(node);

            setCategory(isCategory);
            setLeaf(isLeaf);
            setDeclarative(isDeclarative);
            setNormalized(isNormalized);
            setLanguage(isLanguage);
            setHobbies(isHobbies);
            setGeneralization(isGeneralization);
            setComposition(isComposition);

    }, [node])

    /**
     * Event handlers
     */

    const handleChange = (attribute) => {
        setNode({ ...node, ...attribute });
    }

    const handleSave = () => {
        onSave && onSave(node);
    }

    return (

        <div id={id} className='modal fade' ref={modalRef}>
            <div className='modal-dialog modal-lg modal-dialog-centered'>
                <div className='modal-content'>

                    <div className='modal-header'>
                        <h2 className='modal-title'>{t('catalog.properties.title')}</h2>
                        <button type='button' className='btn-close' data-bs-dismiss='modal'></button>
                    </div>

                    <div className="modal-body">
                        <div className='container'>

                            <div className='row'>
                                <div className='col-8'>
                                    <label>{t('catalog.properties.name')}</label>
                                    <input type='text' className='form-control' defaultValue={node?.label ?? ''} placeholder={t('catalog.properties.name-placeholder')} onChange={(e) => handleChange({ label: e.target.value })} />
                                </div>
                                {
                                    <div className='col-2 form-group'>
                                        <label htmlFor='range' className={!isDeclarative ? 'text-muted' : ''}>{t('catalog.category.levels')}</label>
                                        {
                                            !isDeclarative &&
                                            <input id='range' className='form-control' type='text' style={{ background: 'none'}} disabled></input>
                                        }
                                        {
                                            isDeclarative &&
                                            <select id='range' className='form-select' value={node?.levels ?? 3} onChange={(e) => handleChange({ levels: +e.target.value })}>
                                                <option value={3}>3</option>
                                                <option value={5}>5</option>
                                            </select>
                                        }
                                    </div>
                                }
                            </div>

                            <div className='row pt-2'>
                                <div className='col'>
                                    <label>{t('catalog.properties.description')}</label>
                                    <textarea className='form-control' rows='3' value={node?.description ?? ''} placeholder={t('catalog.properties.description-placeholder')} style={{ overflowY: 'scroll' }} onChange={(e) => handleChange({ description: e.target.value })} />
                                </div>
                            </div>

                            <div className='row py-3'>

                                <div className='col'>
                                    <div>
                                        <fieldset className='col form-group border rounded-3 p-3 pb-4' disabled={!isCategory}>

                                            <legend className={'float-none w-auto px-2 fs-6' + (!isCategory ? ' text-muted' : '')}>{t('catalog.category.title')}</legend>

                                            <div className='form-check'>
                                                <input className='form-check-input' type='radio' checked={isDeclarative ?? false} onChange={() => handleChange({ type: 'declarative' })} />
                                                <label className='form-check-label'>{t('catalog.category.declarative')}</label>
                                            </div>

                                            <div className='form-check'>
                                                <input className='form-check-input' type='radio' checked={isNormalized ?? false} onChange={() => handleChange({ type: 'normalized' })} />
                                                <label className='form-check-label'>{t('catalog.category.normalized')}</label>
                                            </div>

                                            <div className='form-check'>
                                                <input className='form-check-input' type='radio' checked={isLanguage ?? false} onChange={() => handleChange({ type: 'language' })} />
                                                <label className='form-check-label'>{t('catalog.category.language')}</label>
                                            </div>

                                            <div className='form-check'>
                                                <input className='form-check-input' type='radio' checked={isHobbies ?? false} onChange={() => handleChange({ type: 'hobbies' })} />
                                                <label className='form-check-label'>{t('catalog.category.hobbies')}</label>
                                            </div>

                                        </fieldset>
                                    </div>
                                </div>

                                <div className='col'>
                                    <fieldset className='form-group border rounded-3 p-3 pb-4' disabled={isLeaf}>

                                        <legend className={'float-none w-auto px-2 fs-6' + (isLeaf ? ' text-muted' : '')}>{t('catalog.children.title')}</legend>

                                        <div className='form-check'>
                                            <input className='form-check-input' type='radio' checked={isGeneralization ?? false} onChange={() => handleChange({ childrenType: null })} />
                                            <label className='form-check-label'>{t('catalog.children.generalization')}</label>
                                        </div>

                                        <div className='form-check'>
                                            <input className='form-check-input' type='radio' checked={isComposition ?? false} onChange={() => handleChange({ childrenType: 'partition' })} />
                                            <label className='form-check-label'>{t('catalog.children.holonym')}</label>
                                        </div>

                                        <div className='form-check'>
                                            <label className='form-check-label'></label>
                                        </div>

                                    </fieldset>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className='modal-footer'>
                        <div className='btn-group'>
                            <button className='btn btn-light' data-bs-dismiss='modal' onClick={handleSave}>{t('button.save')}</button>
                            <button className='btn btn-light' data-bs-dismiss='modal'>{t('button.cancel')}</button>
                        </div>
                    </div>

                </div>
            </div >
        </div >
    )
}

export default PropertyDialog;