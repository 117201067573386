
import useUserController from './useUserController.js';
import useExperienceController from './useExperienceController.js';
import useFormationController from './useFormationController.js';
import CurriculumService from '../services/CurriculumService.js';
import LangUtil from "../utils/LangUtil.js";

const useCurriculumController = () => {

    const userController = useUserController();
    const experienceController = useExperienceController();
    const formationController = useFormationController();

    const getPublicCurriculum = async (userId, lang) => {

        const data = await CurriculumService.getPublicCurriculum(userId);

        LangUtil.translateTree(data.catalog, lang);

        for(const experience of data.experiences) {
            experienceController.translate(experience, lang);
            experience.start = experience.start ? new Date(experience.start) : null;
            experience.end   = experience.end ? new Date(experience.end) : null;
        }

        for(const formation of data.formations) {
            formationController.translate(formation, lang);
        }
         
        userController.translate(data.user, lang);
        return data;
    }

    const getPrivateCurriculum = async (userId, motionId = null, lang) => {

        const data = motionId
            ? await CurriculumService.getPrivateCurriculum(userId, motionId)
            : await CurriculumService.getPrivateCurriculum(userId);

        LangUtil.translateTree(data.catalog, lang);
        data.motionCatalog && LangUtil.translateTree(data.motionCatalog, lang);

        for(const experience of data.experiences) {
            experienceController.translate(experience, lang);
            experience.start = experience.start ? new Date(experience.start) : null;
            experience.end   = experience.end ? new Date(experience.end) : null;
        }

        for(const formation of data.formations) {
            formationController.translate(formation, lang);
        }
         
        userController.translate(data.user, lang);

        return data;
    }

    return {
        getPublicCurriculum,
        getPrivateCurriculum
    }
}

export default useCurriculumController;