
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';

import AppSettings from '../../../logic/AppSettings.js';
import CatalogLogic from '../../../logic/CatalogLogic.js';

import UserBox from './UserBox.js';
import FormationBox from './FormationBox.js';
import LanguageBox from './LanguageBox.js';
import HobbiesBox from './HobbiesBox.js';
import ReferenceBox from './ReferenceBox.js';
import ExperienceBox from './ExperienceBox.js';
import Legend from './Legend.js';
import BiographyBox from './BiographyBox.js';
import LevelBar from './LevelBar.js';

import './CurriculumView.css';

const CurriculumView = ({ data }) => {

    const LINES_PER_PAGE  = 54;
    const LINES_GREETINGS = 2;
    const LINES_LEGEND    = 6;

    /**
     * Page state
     */

    const ref = useRef();
    const { t } = useTranslation();
    const { auth } = useAuthContext();

    const [user, setUser] = useState();
    const [languages, setLanguages] = useState([]);
    const [formations, setFormations] = useState([]);
    const [experiences, setExperiences] = useState([]);
    const [hobbies, setHobbies] = useState([]);
    const [skills, setSkills] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [firstPageLineCount, setFirstPageLineCount] = useState(0);
    const [otherPageLineCount, setOtherPageLineCount] = useState(0);

    /**
     * Page lifecycle
     */

    useEffect(() => {

        if (data) {

            // Retrieve curriculum dependencies
            const languages = CatalogLogic.getLanguageSkills(data.catalog);
            const hobbies = CatalogLogic.getHobbySkills(data.catalog);
            const skills = CatalogLogic.getFlatSkills(data.catalog);
            const biography = data.user.biography?.length > 0 ? data.user.biography : AppSettings.DUMMY_PARAGRAPH;
            const experiences = data.experiences ?? [];
            const formations = data.formations ?? [];

            // Calculate page metrics
            const biographyLines = biography.length ? 3 : 0;
            const experienceLines = experiences.length * 4;
            const firstPageLineCount = LINES_PER_PAGE - (LINES_GREETINGS + biographyLines + experienceLines + LINES_LEGEND);
            const otherPageLineCount = LINES_PER_PAGE - LINES_LEGEND;
            const pageCount = Math.ceil(1 + (skills.length - firstPageLineCount) / otherPageLineCount);

            setUser(data.user);
            setLanguages(languages);
            setFormations(formations);
            setExperiences(experiences);
            setHobbies(hobbies);
            setSkills(skills);
            setFirstPageLineCount(firstPageLineCount);
            setOtherPageLineCount(otherPageLineCount);
            setPageCount(pageCount);
        }

        // eslint-disable-next-line 
    }, [data, auth.lang]);

    useEffect(() => {

        if (!data || !ref.current) return

        // Set curriculum css vars
        const curriculum = ref.current;
        const colors = ['red', 'green', 'blue', 'yellow'];
        const theme = user?.color ? colors[+user.color] : 'red';
        curriculum.style.setProperty('--curriculum-theme', `var(--curriculum-theme-${theme})`);

        // eslint-disable-next-line 
    }, [user]);

    return (

        <div className='curriculum' ref={ref}>
            {
                [...Array(pageCount).keys()].map(page => {

                    const start = page === 0 ? 0 : firstPageLineCount + ((page - 1) * otherPageLineCount);
                    const end   = page === 0 ? firstPageLineCount : start + otherPageLineCount;

                    return (
                        <div key={page} className='curriculum page container'>

                            <div className='d-flex'>
                                <div className='page-top-bar' />
                                <div className='page-top-count'>{t('mycv.page')}: {page + 1}</div>
                            </div>

                            <div className='row'>

                                <div className='col-4'>

                                    <UserBox user={user} />
                                    {page === 0 && <FormationBox formations={formations} />}
                                    {page === 0 && <LanguageBox languages={languages} />}
                                    {page === 0 && <HobbiesBox hobbies={hobbies} />}
                                    { page === 0 && <ReferenceBox /> }

                                </div>

                                <div className='col'>

                                    {page === 0 && <BiographyBox user={user} />}
                                    {page === 0 && <ExperienceBox experiences={experiences} />}
                                    {page !== 0 && <hr />}

                                    <Legend />

                                    <div className='skills-box'>
                                        {
                                            skills.slice(start, end).map((item) => (
                                                <div key={item.id}>

                                                    {
                                                        item.type === 'category' &&
                                                        <div className='skill-category'>{item.label}</div>
                                                    }

                                                    {
                                                        item.type !== 'category' &&
                                                        <LevelBar key={item.id} level={item.level} label={item.label} />
                                                    }

                                                </div>
                                            ))
                                        }
                                    </div>

                                </div>

                            </div>

                        </div>
                    )
                })
            }

        </div>
    )
}


export default CurriculumView;
