
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import useUserController from '../controllers/useUserController.js';
import useSectorController from '../controllers/useSectorController.js';

import ExperienceService from '../services/ExperienceService.js';
import LangUtil from "../utils/LangUtil.js";

const useExperienceController = () => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();
    const [loaded, setLoaded] = useState(true);

    const userController = useUserController();
    const sectorController = useSectorController();

    /**
     * Functionalities
     */

    const getExperiences = async (filter = null) => {

        try {
            setLoaded(false);
            const data = await ExperienceService.getExperiences(auth.user, filter);
            data.items.forEach(item => translate(item, auth.lang));
            return data;
        }
        finally {
            setLoaded(true);
        }
    }

    const getExperience = async (id) => {
        if (id == null || id.trim().length === 0) return null;
        const experience = await ExperienceService.getExperience(id);
        translate(experience, auth.lang);
        return experience;
    }

    const deletable = async (experience) => {
        return experience.id != null && await ExperienceService.isDeletable(experience);
    }

    const create = async (user) => {
        const experience = await ExperienceService.create(user);
        translate(experience);
        return experience;
    }

    const save = async (experience) => {

        try {
            untranslate(experience);
            await ExperienceService.save(experience);
        }
        finally {
            translate(experience);
        }
    }

    const remove = async (experience) => {
        await ExperienceService.remove(experience);
    }

    const translate = (experience) => {

        LangUtil.translate(experience, 'description', auth.lang);
        
        experience.user && userController.translate(experience.user);
        experience.sector && sectorController.translate(experience.sector);
    }

    const untranslate = (experience) => {

        LangUtil.untranslate(experience, 'description');

        experience.user && userController.untranslate(experience.user);
        experience.sector && sectorController.untranslate(experience.sector);
    }

    const retranslate = (experiences) => {
        for (const experience of experiences) {
            untranslate(experience);
            translate(experience);
        }
    }

    return {
        loaded,
        getExperience,
        getExperiences,
        deletable,
        create,
        save,
        remove,
        translate,
        untranslate,
        retranslate
    }
}

export default useExperienceController;