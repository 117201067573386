
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';

import avatar_img from '../../../assets/images/avatar.svg';

import './Curriculum.css';

const CurriculumHeader = ({ user, ...props }) => {

    /**
     * Page state
     */

    const { t } = useTranslation();
    const { auth } = useAuthContext();
    // const availabilities = t('common.availabilities');
    const [title, setTitle] = useState();
    // const [subtitle, setSubtitle] = useState({});
    const [location, setLocation] = useState();

    /**
     * Page lifecycle
     */

    useEffect(() => {

        const title = user.firstname && user.lastname ? user.firstname + ' ' + user.lastname : null;
        setTitle(title);
        
        // const availability = availabilities.find(a => a.id === user.availability);
        // setSubtitle(availability);
        
        const location = user.city;
        setLocation(location);

        // eslint-disable-next-line 
    }, [user, auth.lang]);

    return (

        <div {...props}>
            <div {...props} className={props.className + ' d-flex'}>

                <div className='col-1 ms-3'>
                    <img className='avatar' src={user.picture ?? avatar_img} alt='avatar'></img>
                </div>

                <div className='col-2 ms-2 p-2'>

                    <div className='flex-fill d-flex flex-column align-items-start justify-content-center'>

                        <h5>
                            {title}
                        </h5>

                        {/* <h6>
                            <span className='table-header text-muted'>{t('glossary.availability')}: </span>
                            {subtitle && <span>{subtitle.name}</span>}
                        </h6> */}

                        <h6>
                            <span className='table-header text-muted'>{t('glossary.location')}: </span>
                            {location && <span>{location}</span>}
                        </h6>

                    </div>

                </div>
            </div>

            {
                user.positionTitle &&
                <div className='row pt-3 pb-4'>
                    <h5><strong>{user.positionTitle.toUpperCase()}</strong></h5>
                    <h6>{user.positionDescription}</h6>
                </div>
            }

        </div>
    )
}

export default CurriculumHeader
