
import { useAuthContext } from './controllers/useAuthContext.js';
import { Routes, Route } from 'react-router-dom';
import PrivateRoutes from './pages/public/PrivateRoutes.js';
import NavBar from './components/navbar/NavBar.js';

/* public pages */
import Login from './pages/public/Login.js';
import Forgot from './pages/public/Forgot.js';
import Reset from './pages/public/Reset.js';
import Logout from './pages/public/Logout.js';
import Register from './pages/public/Register.js';
import Unauthorized from './pages/public/Unauthorized.js';
import PageNotFound from './pages/public/PageNotFound.js';
import PublicCurriculum from './pages/public/PublicCurriculum.js';

/* admin pages */
import AdminUsers from './pages/admin/users/Users.js';
import Companies from './pages/admin/companies/Companies.js';
import Countries from './pages/admin/countries/Countries.js';
import AdminCatalog from './pages/admin/catalog/SkillsCatalog.js';
import UserRoles from './pages/admin/userroles/UserRoles.js';
import UserTypes from './pages/admin/usertypes/UserTypes.js';
import Sectors from './pages/admin/sectors/Sectors.js';
import DiplomaTypes from './pages/admin/diplomatypes/DiplomaTypes.js';
import Wishes from './pages/admin/wishes/Wishes.js';

/* manager pages */
import CompanyDetail from './pages/manager/company/CompanyDetail.js';
import ManagerUsers from './pages/manager/users/Users.js';
import Workplaces from './pages/manager/workplaces/Workplaces.js';
import ManagerCatalog from './pages/manager/catalog/SkillsCatalog.js';
import Surveys from './pages/manager/surveys/Surveys.js';
import SurveyChart from './pages/manager/surveys/SurveyChart.js';
import SurveyDetail from './pages/manager/surveys/SurveyDetail.js';
import Motions from './pages/manager/motions/Motions.js';
import MotionDetail from './pages/manager/motions/MotionDetail.js';
import PositionTypes from './pages/manager/postypes/PositionTypes.js';
import Inventory from './pages/manager/inventory/Inventory.js';
import UserReview from './pages/manager/reviews/SkillCatalog.js';

/* user pages */
import Home from './pages/user/home/Home.js';
import PrivateCurriculum from './pages/user/curriculum/PrivateCurriculum.js';
import Invitations from './pages/user/invitations/Invitations.js';
import InvitationDetail from './pages/user/invitations/InvitationDetail.js';
import Opportunities from './pages/user/opportunities/Opportunities.js';
import UserCatalog from './pages/user/catalog/SkillCatalog.js';
import Experiences from './pages/user/experiences/Experiences.js';
import Formations from './pages/user/formations/Formations.js';
import Assessments from './pages/user/assessments/Assessments.js';
import Account from './pages/user/account/Account.js';

const App = () => {

    const { auth } = useAuthContext();
    
    return (

        <>
            { auth.user && <NavBar /> }

            <Routes>

                {/* public routes */}
                
                <Route index element={<Login />} />
                <Route path='login' element={<Login />} />
                <Route path='forgot' element={<Forgot />} />
                <Route path='reset' element={<Reset />} />
                <Route path='register' element={<Register />} />
                <Route path='curriculum/:id' element={<PublicCurriculum />} />
                <Route path='unauthorized' element={<Unauthorized />} />

                {/* admin pages */}

                <Route path='admin' element={<PrivateRoutes roles={'ADMIN'} />}>
                    <Route index element={<AdminUsers />} />
                    <Route path='users' element={<AdminUsers />} />
                    <Route path='countries' element={<Countries />} />
                    <Route path='companies' element={<Companies />} />
                    <Route path='catalog' element={<AdminCatalog />} />
                    <Route path='userroles' element={<UserRoles />} />
                    <Route path='usertypes' element={<UserTypes />} />
                    <Route path='sectors' element={<Sectors />} />
                    <Route path='diplomatypes' element={<DiplomaTypes />} />
                    <Route path='wishes' element={<Wishes />} />
                </Route>

                {/* manager pages */}

                <Route path='manager' element={<PrivateRoutes roles={['ADMIN', 'MANAGER']} />}>
                    <Route index element={<ManagerUsers />} />
                    <Route path='company' element={<CompanyDetail />} />
                    <Route path='users' element={<ManagerUsers />} />
                    <Route path='surveys/:id' element={<SurveyDetail />} />
                    <Route path='surveys/:id/charts' element={<SurveyChart />} />
                    <Route path='surveys' element={<Surveys />} />
                    <Route path='motions/:id' element={<MotionDetail />} />
                    <Route path='motions' element={<Motions />} />
                    <Route path='catalog' element={<ManagerCatalog />} />
                    <Route path='inventory' element={<Inventory />} />
                    <Route path='workplaces' element={<Workplaces />} />
                    <Route path='postypes' element={<PositionTypes />} />
                    <Route path='reviews/:id' element={<UserReview />} />
                </Route>

                {/* user pages */}

                <Route path='home' element={<PrivateRoutes roles={'USER'} />}>
                    <Route index element={<Home />} />
                </Route>

                <Route path='user' element={<PrivateRoutes roles={'USER'} />}>
                    <Route path='home' element={<Home />} />
                    <Route path='curriculum' element={<PrivateCurriculum />} />
                    <Route path='curriculum/:id' element={<PrivateCurriculum />} />
                    <Route path='invitations' element={<Invitations />} />
                    <Route path='invitations/:id' element={<InvitationDetail />} />
                    <Route path='opportunities' element={<Opportunities />} />
                    <Route path='catalog' element={<UserCatalog />} />
                    <Route path='experiences' element={<Experiences />} />
                    <Route path='formations' element={<Formations />} />
                    <Route path='assessments' element={<Assessments />} />
                    <Route path='account' element={<Account />} />
                    <Route path='logout' element={<Logout />} />
                </Route>

                {/* catch all routes */}

                <Route path='*' element={<PageNotFound />} />

            </Routes>
        </>

    )
}

export default App;
