
import TreeUtil from './TreeUtil.js';

const LangUtil = () => {

    /**
     * Convert a multilang field into a monolang one, for a specific language, attribute.
     * 
     * For instance:
     *                         multilang field
     *      let                 !
     *          o = { id: '1', name: { en: 'toto': fr: 'titi' }}
     * 
     *      calling method  
     * 
     *          translate(o, 'name', 'fr')
     * 
     *      will result into:   monolang field
     *                           !            multilang field backed up
     *                           !             !
     *          o = { id: '1', name: 'titi', name_: { en: 'toto': fr: 'titi', lang: 'fr' }}
     * 
     *      if label in the specified language doesn't exists, 'en' lang is taken.
     *      if label is still missing, take the first non-null.
     */
    const translate = (obj, field, lang = 'en') => {

        // No previous backup => the field has not yet being translated
        if (obj && typeof obj === 'object' && obj[field + '_'] == null) {
            
            let translatedValue = null;
            const multilangField = typeof obj[field] !== 'object' || obj[field] == null ? {} : obj[field];

            // Check if we have a translation to the current language
            if (multilangField.hasOwnProperty(lang) && multilangField[lang]) {
                translatedValue = multilangField[lang];
            }
            
            // Check if we have a translation to the english (default language)
            else if (multilangField.en) {
                translatedValue = '<' + multilangField.en + '>';
            }
            
            // Otherwise, check if we have another translation available
            else if (Object.keys(multilangField).length > 0) {

                for(const translatedField of Object.values(multilangField)) {
                    if (translatedField) {
                        translatedValue = '<' + translatedField + '>';
                        break;
                    }
                }
            }

            // Set current translation value
            obj[field] = translatedValue;

            // Set from which language the translation comes from
            multilangField.lang = lang;

            // And backup the original multilang field
            obj[field + '_'] = multilangField;
        }
    }

    /**
     * Convert a monolang field into a multilang one, preserving the previous multilang values.
     * 
     * For instance:
     * 
     *      let              
     *          o = { id: '1', name: 'abcd', name_: { en: 'toto': fr: 'titi', lang: 'fr' }}
     * 
     *      calling method
     * 
     *          untranslate(o, 'name')
     * 
     *      will result into:
     * 
     *          o = { id: '1', name: { en: 'toto': fr: 'abcd' }}
     * 
     */
    const untranslate = (obj, field) => {

        if (obj != null && obj.hasOwnProperty(field)) {

            const multilangField = obj[field + '_'] ?? {};
            const lang = multilangField.lang ?? 'en';
            delete multilangField.lang;

            // Add monolang field value into the multilang field
            let translatedValue = obj[field];
            if (translatedValue && translatedValue.length > 1 && translatedValue[0] === '<' && translatedValue.substr(-1) === '>') translatedValue = null;
            multilangField[lang] = translatedValue;

            // Restore the multilang map into the field
            obj[field] = multilangField;
            delete obj[field + '_'];
        }
    }

    /**
     * Translates each node of a tree into the label attribute
     */
    const translateTree = (tree, lang) => {
        TreeUtil.toList(tree).forEach(node => {
            translate(node, 'label', lang);
            translate(node, 'description', lang);
        })
    }

    /**
     * Untranslates each node of a tree and restore the multilang label
     */
    const untranslateTree = (tree) => {
        TreeUtil.toList(tree).forEach(node => {
            untranslate(node, 'label');
            untranslate(node, 'description');
        })
    }

    return {
        translate,
        untranslate,
        translateTree,
        untranslateTree
    }
}

export default LangUtil();