
import { useTranslation } from 'react-i18next';

import writing_img from '../../../assets/images/writing.svg';
import reading_img from '../../../assets/images/reading.svg';
import speaking_img from '../../../assets/images/speaking.svg';

import './LanguageBox.css';

const LanguageBox = ({ languages, ...props }) => {

    const { t } = useTranslation();

    const items = languages.slice(0, 4);
    if (items.length < 1) return null;

    return (
        <div { ...props } className='language-box'>

            <div className='section-title'>{t('glossary.language')}</div>

            <div className='language-section'>
                <div>
                    {
                        items.map((language, key) => (
                            <div key={key} className='pe-2'>
                                <img src={speaking_img} alt='speaking' />
                                <span>{language.speaking}</span>
                                <img src={writing_img} alt='writing' />
                                <span>{language.writing}</span>
                                <img src={reading_img} alt='reading' />
                                <span>{language.reading}</span>
                            </div>
                        ))
                    }
                </div>
                <div>
                    {
                        items.map((language, key) => (
                            <div key={key}>{language.label}</div>
                        ))
                    }
                </div>
            </div>

        </div>
    )
}

export default LanguageBox