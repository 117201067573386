
import { useTranslation } from 'react-i18next';

import './HobbiesBox.css'

const HobbiesBox = ({ hobbies, ...props }) => {

    const { t } = useTranslation();

    const items = hobbies.sort((s1, s2) => s2.level - s1.level).map(skill => skill.label).slice(0, 5);
    if (items.length < 1) return null;

    return (
        <div {...props} className='hobbies-box'>

            <div className='section-title'>{t('catalog.category.hobbies')}</div>

            <div>
                { items.map((hobby, key) => <div key={key}>{hobby}</div>) }
            </div>

        </div>
    )
}

export default HobbiesBox