
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DateUtil from '../../utils/DateUtil.js';
import hr360_img from '../../assets/images/review-spill.svg';

import './Slider.css'

/**
 * references used
 *      https://nikitahl.com/style-range-input-css
 *      https://codepen.io/micahjones/pen/gOWXRjY
 */

const Slider = ({ min, max, value, userLevel = null, reviews = [], disabled = false, onChange }) => {

    const ref = useRef();
    const { t } = useTranslation();
    const [hidden, setHidden] = useState(true);

    useEffect(() => {

        
        if (ref.current) {

            const slider = ref.current;

            // Whenever the radio value change, update the slider track's background size
            const valPercent = (slider.value - slider.min) / (slider.max - slider.min) * 100;
            slider.style.setProperty('--valuePercent', `${valPercent}%`);
            
            // Select userlevel color if thumb is at the same location
            slider.style.setProperty('--thumbColor', (value === userLevel ? '#cce0ff' : '#ffffff') );
        }

    }, [ref, value, userLevel]);

    const handleChange = (e) => {
        onChange(e);
    }

    return (

        <div className='slider' onMouseEnter={() => setHidden(false)} onMouseLeave={() => setHidden(true)}>

            <div>

                <div className='slider-wrapper '>
                    <input
                        type='range'
                        ref={ref}
                        className={'slider-input' + (+value ? '' : ' unselected') + (disabled ? ' disabled' : '')}
                        min={min}
                        max={max}
                        value={Number.isNaN(value) ? '' : value}
                        disabled={disabled}
                        onChange={handleChange} 
                        onMouseDown={(e) => value === 0 && handleChange(e)} // input range bug, if value = 0
                    />
                </div>

                {
                    (userLevel || reviews.length > 0) &&
                    <div className='slider-background'>
                        {
                            [...Array(max - min + 1).keys()].map(level => {

                                const reviewsForLevel = reviews.filter(r => r.level === level);
                                const reviewText = `<div class='text-start'><strong>${t('glossary.reviews')}</strong>${reviewsForLevel.map(review => `<div>${DateUtil.toLocaleString(new Date(review.date))} ${review.firstname} ${review.lastname}</div>`).join('')}</div>`;

                                return (
                                    <div key={level}>

                                        {
                                            userLevel === level + 1 && userLevel !== value &&
                                            <div className='userlevel' />
                                        }
                                        {
                                            reviewsForLevel.length > 0 &&
                                            <div className='spillgroup' data-bs-toggle='tooltip' data-bs-title={reviewText} data-bs-placement='right' data-bs-html='true'>
                                                {reviewsForLevel.length > 0 && <img src={hr360_img} alt='360' />}
                                                {reviewsForLevel.length > 1 && <img src={hr360_img} alt='360' />}
                                                {reviewsForLevel.length > 2 && <img src={hr360_img} alt='360' />}
                                            </div>
                                        }

                                    </div>
                                )
                            })
                        }
                    </div>
                }

            </div>

            <button
                type='button'
                className='btn btn-xs btn-danger'
                style={{ opacity: !value || hidden ? 0 : 1 }}
                onClick={() => handleChange({target:{value:0}})}>
                {t('glossary.delete')}
            </button>

        </div>
    )
}

export default Slider