
import './LevelBar.css';

const LevelBar = ({ level, label, ...props }) => {

    return (
        <div {...props} className='level-bar'>

            <div>
                {
                    [...Array(5)].map((_, i) => <div key={i} className={`spill ${i < level ? '' : 'inactive'}`} />)
                }
            </div>

            <div className={`${level < 3 ? 'disabled' : ''}`}>
                {label}
            </div>

        </div>
    )
}

export default LevelBar