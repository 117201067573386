
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import useCurriculumController from '../../../controllers/useCurriculumController.js';

import LoadingIcon from '../../../components/loader/LoadingIcon.js';
import CurriculumView from '../../../components/curriculum/standard/CurriculumView.js';

import print_img from '../../../assets/images/print.svg';

const PrivateCurriculum = () => {

    /**
     * Page state
     */

    const { id } = useParams();
    const { t } = useTranslation();
    const { auth } = useAuthContext();
    const [data, setData] = useState();
    const [loaded, setLoaded] = useState(false);

    const curriculumController = useCurriculumController();

    /**
     * Page lifecycle
     */

    useEffect(() => {

        (async () => {

            try {
                setLoaded(false);
                const data = await curriculumController.getPrivateCurriculum(id ?? auth.user.id, null, auth.lang);

                setData(data);
            }
            finally {
                setLoaded(true);
            }

        })()

        // eslint-disable-next-line 
    }, [auth.lang]);


    /**
     * Event handlers
     */

    const handlePrint = () => {
        window.print();
    }

    return (

        <div className='container'>
            <div className='row p-4'>

                <LoadingIcon loading={!loaded} />

                <div className='title-bar'>
                    <div>{t('mycv.title')}</div>
                    <div><button type='button' className='btn btn-light' onClick={handlePrint}><img src={print_img} alt='edit' /></button></div>
                </div>
                
                <hr />

                <CurriculumView data={data} />

            </div>
        </div>

    )
}


export default PrivateCurriculum
