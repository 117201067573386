
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../controllers/useAuthContext.js';
import AppConfig from '../../../logic/AppSettings.js';

const TabLinks = ({ user, onChange }) => {

    /**
     * Page state
    */

    const { t } = useTranslation();
    const { auth } = useAuthContext();

    /**
     * Event handlers
     */

    const handleAttributeChange = (attr) => {
        onChange(attr);
    }

    return (

        <div className='container'>
            <div className='row pt-3'>
                <div className='col-8'>

                    <div className='form-group'>
                        <label htmlFor='identifier'>{t('glossary.identifier')}</label>
                        <input id='identifier' type='text' className='form-control text-muted' value={user.id ?? ''} readOnly />
                    </div>

                    <div className='form-group pt-2'>
                        <label htmlFor='phone'>{t('users.phone')}</label>
                        <input id='phone' type='text' className='form-control' value={user.phone ?? ''} placeholder={t('users.phone-placeholder')} autoComplete='off' onChange={(e) => handleAttributeChange({ phone: e.target.value })} />
                    </div>

                    <div className='form-group pt-2'>
                        <label htmlFor='linkedin'>{t('profile.linkedin')}</label>
                        <input id='linkedin' type='text' className='form-control' value={user.linkedin ?? ''} placeholder={t('profile.linkedin-placeholder')} autoComplete='off' onChange={(e) => handleAttributeChange({ linkedin: e.target.value })} />
                    </div>

                    <div className='form-group pt-2'>
                        <label>{t('profile.public-cv')}</label>
                        <a href={`${AppConfig.FRONTEND_URL}/curriculum/${user.id}?lang=${auth.lang}`} className='form-control link'>{`${AppConfig.FRONTEND_URL}/curriculum/${user.id}?lang=${auth.lang}`}</a>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default TabLinks;
