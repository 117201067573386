
import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useCurriculumController from '../../controllers/useCurriculumController.js';

import LoadingIcon from '../../components/loader/LoadingIcon.js';
import CurriculumView from '../../components/curriculum/standard/CurriculumView.js';

const PublicCurriculum = () => {

    /**
     * Page state
     */

    const { id } = useParams();
    const [ queryParams ] = useSearchParams();
    const [data, setData] = useState();
    const [loaded, setLoaded] = useState(false);

    const curriculumController = useCurriculumController();

    /**
     * Page lifecycle
     */

    useEffect(() => {

        (async () => {

            try {
                setLoaded(false);
                const lang = queryParams.get('lang') ?? 'en';
                const data = await curriculumController.getPublicCurriculum(id, lang);
                setData(data);
            }
            finally {
                setLoaded(true);
            }

        })()

        // eslint-disable-next-line 
    }, [id, queryParams]);

    return (

        <div className='container'>
            <div className='row p-4'>

                <LoadingIcon loading={!loaded} />
                <CurriculumView data={data} />
                
            </div>
        </div >
    )
}

export default PublicCurriculum
