
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import WorkplaceService from '../services/WorkplaceService.js';
import LangUtil from "../utils/LangUtil.js";

import useCompanyController from '../controllers/useCompanyController.js';
import useCountryController from '../controllers/useCountryController.js';

const useWorkplaceController = () => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();
    const [loaded, setLoaded] = useState(true);

    const companyController = useCompanyController();
    const countryController = useCountryController();

    /**
     * Functionalities
     */

    const getWorkplaces = async (filter) => {

        try {
            setLoaded(false);
            const data = await WorkplaceService.getWorkplaces(auth.company, filter);
            data.items.forEach(item => translate(item, auth.lang));
            return data;
        }
        finally {
            setLoaded(true);
        }
    }

    const getWorkplace = async (id) => {
        if (id == null || id.trim().length === 0) return null;
        const workplace = await WorkplaceService.getWorkplace(id);
        translate(workplace, auth.lang);
        return workplace;
    }

    const deletable = async (workplace) => {
        return workplace.id != null && await WorkplaceService.isDeletable(workplace);
    }

    const create = async () => {
        const workplace = await WorkplaceService.create(auth.company);
        translate(workplace, auth.lang);
        return workplace;
    }

    const save = async (workplace) => {

        try {
            untranslate(workplace);
            await WorkplaceService.save(workplace);
        }
        finally {
            translate(workplace, auth.lang);
        }
    }

    const remove = async (workplace) => {
        await WorkplaceService.remove(workplace);
    }

    const translate = (workplace) => {
        LangUtil.translate(workplace, 'name', auth.lang);
        workplace.country && countryController.translate(workplace.country);
        workplace.company && companyController.translate(workplace.company);
    }

    const untranslate = (workplace) => {
        LangUtil.untranslate(workplace, 'name');
        workplace.country && countryController.untranslate(workplace.country);
        workplace.company && companyController.untranslate(workplace.company);
    }

    const retranslate = (workplaces) => {
        for (const workplace of workplaces) {
            untranslate(workplace);
            translate(workplace);
        }
    }

    return {
        loaded,
        getWorkplace,
        getWorkplaces,
        deletable,
        create,
        save,
        remove,
        translate,
        untranslate,
        retranslate
    }
}

export default useWorkplaceController;