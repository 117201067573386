
import { useTranslation } from 'react-i18next';
import AppSettings from '../../../logic/AppSettings.js';

import './BiographyBox.css';

const BiographyBox = ({ user, ...props }) => {

    const { t } = useTranslation();

    const biography = user.biography?.trim().length > 0 ? user.biography : AppSettings.DUMMY_PARAGRAPH;

    return (
        <div {...props} className='biography-box'>

            <div>
                <span>{t('mycv.greeting')} </span> 
                <span>{user.firstname} {user.lastname}</span>
            </div>

            <div>
                {biography}
            </div>

            <hr />

        </div>
    )
}

export default BiographyBox