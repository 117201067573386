
import { useTranslation } from 'react-i18next';

const ReferenceBox = ({ ...props }) => {

    const { t } = useTranslation();

    return (
        <div {...props} className='reference-box'>

            <div className='section-title'>{t('mycv.references')}</div>

            <div>
                {t('mycv.on-demand')}
            </div>

        </div>
    )
}

export default ReferenceBox