
import axios from "axios";
import AppSettings from '../logic/AppSettings.js';

const CurriculumService = () => {

    const getPublicCurriculum = async (userId) => {
        const url = `${AppSettings.BACKEND_URL}/curriculum/public/${userId}`;
        return (await axios.get(url)).data.payload;
    }

    const getPrivateCurriculum = async (userId, motionId) => {

        const url = motionId
            ? `${AppSettings.BACKEND_URL}/curriculum/private/${userId}/${motionId}`
            : `${AppSettings.BACKEND_URL}/curriculum/private/${userId}`;

        return (await axios.get(url)).data.payload;
    }


    return {
        getPublicCurriculum,
        getPrivateCurriculum
    }
}

export default CurriculumService();