
import ObjectUtil from './ObjectUtil.js';

const TreeUtil = () => {

    /**
     * Find a node by its id.
     * Start searching from the provided node
     */
    const findById = (data, nodeId) => {
        if (!data || !nodeId) return null;
        return find(data, n => n.id === nodeId);
    }

    /**
     * Find a node's parent directly with the nodeId
     */
    const findParent = (data, nodeId) => {
        if (!data || !nodeId) return null;
        return find(data, n => n.children?.find(c => c.id === nodeId));
    }

    /**
     * Find the next node in the tree
     * Note: if a node is closed, all its children are not visited
     */
    const findNext = (data, nodeId) => {

        if (!data || !nodeId) return null;

        // Get the data as a flat list
        const nodes = toOpenList(data);

        // Now retrieve the next index of the node to find
        const nodeIndex = nodes.findIndex(n => n.id === nodeId) + 1;

        // If index is invalid, return null
        if (nodeIndex === 0 || nodeIndex > nodes.length - 1) {
            return null;
        }

        return nodes[nodeIndex];
    }

    /**
     * Insert a node before a target node
     */
    const addBefore = (data, target, node) => {

        if (!data || !target || !node) return null;

        // Find the node's parent
        const parent = findParent(data, target.id);

        if (parent) {
            const index = parent.children?.findIndex(child => child.id === target.id);
            parent.children.splice(index, 0, node);
        }
        else {

            // Make the node the new root, but without changing data reference
            const rootClone = ObjectUtil.clone(data);

            // Delete all data attributes
            for (const attrName of Object.keys(data)) delete data[attrName];

            // Restore data attributes from node attributes
            for (const attrName of Object.keys(node)) data[attrName] = node[attrName];

            // Add the old root node to the new root
            data.children = node.children ? [rootClone, ...node.children] : [rootClone];
        }
    }

    /**
     * Insert a node inside a target node
     */
    const addInside = (data, target, node) => {
        if (!data || !target || !node) return null;
        target.children = target.children ? [node, ...target.children] : [node];
    }

    /**
 * Insert a node before a target node
 */
    const addAfter = (data, target, node) => {

        if (!data || !target || !node) return null;

        // If target has children, make the newNode the first one
        if (target.children) {
            target.children = [node, ...target.children];
        }

        // Otherwise, add the node after the target
        else {
            const parent = findParent(data, target.id);

            if (parent) {
                const index = parent.children?.findIndex(child => child.id === target.id);
                parent.children.splice(index + 1, 0, node);
            }
        }
    }

    /**
     * Remove a node by its id
     */
    const remove = (data, nodeId) => {

        if (!data || !nodeId) return null;

        const parent = findParent(data, nodeId);

        if (parent && parent.children) {
            parent.children = parent.children.filter(child => child.id !== nodeId);
            if (parent.children.length === 0) delete parent.children;
        }
    }

    /**
     * Iterate over all nodes from the root 'data'
     * and stop when the first node is passing the callback test.
     */
    const find = (data, callback) => {

        if (!data || !callback) return null;

        if (callback(data)) {
            return data;
        }

        if (data.children) {
            for (const node of data.children) {
                const subnode = find(node, callback);
                if (subnode) return subnode;
            }
        }

        return null;
    }

    /**
    * Iterate over all nodes from the root 'data'
    * and keep only nodes passing the callback test.
    */
    const filter = (data, callback) => {

        if (!data || !callback) return null;

        const nodes = [];

        if (callback(data)) {
            nodes.push(data);
        }

        if (data.children) {
            for (const node of data.children) {
                const matchingChildren = filter(node, callback);
                nodes.push(...matchingChildren);
            }
        }

        return nodes;
    }

    /**
     * Convert a tree into a list of nodes
     * if withparent is true, each children have a new parent attribute
     */
    const toList = (root, withparent = false, list = []) => {

        if (root) {

            list.push(root);

            if (root.children) {
                for (const child of root.children) {
                    if (withparent) child.parent = root;
                    toList(child, withparent, list);
                }
            }
        }

        return list;
    }    

    /**
     * Convert a data tree into a map
     */

    const toMap = (root) => {

        const map = new Map();

        if (root) {

            map.set(root.id, root);

            root.children?.forEach(child => {
                const childMap = toMap(child);
                childMap.forEach((value, key) => map.set(key, value));
            })
        }

        return map;
    }

    /**
     * Same as toList, but only return a list of opened nodes
     */

    const toOpenList = (data) => {

        const nodes = [];

        if (data) {

            nodes.push(data);

            if (data.children && (!data.state || data.state.opened)) {

                data.children.forEach(node => {
                    const children = toOpenList(node);
                    nodes.push(...children);
                })
            }
        }

        return nodes;
    }

    return {
        find,
        filter,
        findById,
        findParent,
        findNext,
        addBefore,
        addInside,
        addAfter,
        remove,
        toList,
        toMap,
        toOpenList
    }
}

export default TreeUtil();
