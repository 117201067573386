
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import UserRoleService from '../services/UserRoleService.js';
import LangUtil from "../utils/LangUtil.js";

const useUserRoleController = () => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();
    const [loaded, setLoaded] = useState(true);

    /**
     * Functionalities
     */

    const getUserRoles = async (filter = null) => {

        try {
            setLoaded(false);
            const data = await UserRoleService.getUserRoles(filter);
            data.items.forEach(item => translate(item, auth.lang));
            return data;
        }
        finally {
            setLoaded(true);
        }
    }

    const getUserRole = async (id) => {
        if (id == null || id.trim().length === 0) return null;
        const userrole = await UserRoleService.getUserRole(id);
        translate(userrole, auth.lang);
        return userrole;
    }

    const save = async (userrole) => {

        try {
            untranslate(userrole);
            await UserRoleService.save(userrole);
        }
        finally {
            translate(userrole, auth.lang);
        }
    }

    const translate = (userRole) => {
        LangUtil.translate(userRole, 'name', auth.lang);
        LangUtil.translate(userRole, 'description', auth.lang);
    }

    const untranslate = (userRole) => {
        LangUtil.untranslate(userRole, 'name');
        LangUtil.untranslate(userRole, 'description');
    }

    const retranslate = (userRoles) => {
        for (const userRole of userRoles) {
            untranslate(userRole);
            translate(userRole);
        }
    }

    return {
        loaded,
        getUserRole,
        getUserRoles,
        save,
        translate,
        untranslate,
        retranslate
    }
}

export default useUserRoleController;