
import { useTranslation } from 'react-i18next';

const FormationBox = ({ formations, ...props }) => {

    const { t } = useTranslation();
     
    const items = formations.sort((e1, e2) => e2.year - e1.year).slice(0, 4);
    if (items.length < 1) return null;

    return (
        <div {...props} className='formation-box'>

            <div className='section-title'>{t('glossary.formation')}</div>

            {
                items.map((formation) => (
                    <div key={formation.id} className='row pb-2'>
                        <div className='col'>
                            <div><b><i>{formation.diploma ?? '-'}</i></b></div>
                            <div>{formation.school ?? '-'}</div>
                            <div className='text-muted'>{formation.year}</div>
                        </div>
                    </div>
                ))
            }

        </div>
    )
}

export default FormationBox