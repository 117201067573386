
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import PositionTypeService from '../services/PositionTypeService.js';
import LangUtil from "../utils/LangUtil.js";

import useCompanyController from '../controllers/useCompanyController.js';

const usePositionTypeController = () => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();
    const [loaded, setLoaded] = useState(true);

    const companyController = useCompanyController();

    /**
     * Functionalities
     */

    const getPositionTypes = async (filter = null) => {

        try {
            setLoaded(false);
            const data = await PositionTypeService.getPositionTypes(auth.company, filter);
            data.items.forEach(item => translate(item, auth.lang));
            return data;
        }
        finally {
            setLoaded(true);
        }
    }

    const getPositionType = async (id) => {
        if (id == null || id.trim().length === 0) return null;
        const positionType = await PositionTypeService.getPositionType(id);
        translate(positionType, auth.lang);
        return positionType;
    }

    const deletable = async (positionType) => {
        return positionType.id != null && await PositionTypeService.isDeletable(positionType);
    }

    const create = async () => {
        const positionType = await PositionTypeService.create(auth.company);
        translate(positionType);
        return positionType;
    }

    const save = async (positionType) => {

        try {
            untranslate(positionType);
            await PositionTypeService.save(positionType);
        }
        finally {
            translate(positionType);
        }
    }

    const remove = async (positionType) => {
        await PositionTypeService.remove(positionType);
    }

    const translate = (positionType) => {
        LangUtil.translate(positionType, 'name', auth.lang);
        positionType.company && companyController.translate(positionType.company);
    }

    const untranslate = (positionType) => {
        LangUtil.untranslate(positionType, 'name');
        positionType.company && companyController.untranslate(positionType.company);
    }

    const retranslate = (positionTypes) => {
        for (const positionType of positionTypes) {
            untranslate(positionType);
            translate(positionType);
        }
    }

    return {
        loaded,
        getPositionType,
        getPositionTypes,
        deletable,
        create,
        save,
        remove,
        translate,
        untranslate,
        retranslate
    }
}

export default usePositionTypeController;