
import { useState } from 'react';
import { useAuthContext } from '../controllers/useAuthContext.js';
import CompanyService from '../services/CompanyService.js';
import useCountryController from './useCountryController.js';

const useCompanyController = () => {

    /**
     * Component state
     */

    const { auth } = useAuthContext();
    const [loaded, setLoaded] = useState(true);
    const countryController = useCountryController();

    /**
     * Functionalities
     */

    const getCompanies = async (filter = null) => {

        try {
            setLoaded(false);
            const data = await CompanyService.getCompanies(filter);
            data.items.forEach(item => translate(item, auth.lang));
            return data;
        }
        finally {
            setLoaded(true);
        }
    }

    const getCompany = async (id) => {
        if (id == null || id.trim().length === 0) return null;
        const company = await CompanyService.getCompany(id);
        translate(company);
        return company;
    }

    const getPicture = async (id) => {
        if (id == null || id.trim().length === 0) return null;
        return await CompanyService.getPicture(id);
    }

    const deletable = async (company) => {
        return company.id != null && await CompanyService.isDeletable(company);
    }

    const create = async () => {

        const company = await CompanyService.create();
        translate(company);
        return company;
    }

    const save = async (company) => {

        try {
            untranslate(company);
            await CompanyService.save(company);
        }
        finally {
            translate(company);
        }
    }

    const remove = async (company) => {
        await CompanyService.remove(company);
    }

    const translate = (company) => {
        company.country && countryController.translate(company.country, auth.lang);
    }

    const untranslate = (company) => {
        company.country && countryController.untranslate(company.country);
    }

    const retranslate = (companies) => {
        for (const company of companies) {
            untranslate(company);
            translate(company);
        }
    }

    return {
        loaded,
        getCompany,
        getPicture,
        getCompanies,
        deletable,
        create,
        save,
        remove,
        translate,
        untranslate,
        retranslate,
    }
}

export default useCompanyController;