
import { useTranslation } from 'react-i18next';

const TabMyCV = ({ user, onChange }) => {

    /**
     * Page state
    */

    const { t } = useTranslation();

    /**
     * Event handlers
     */

    const handleAttributeChange = (attr) => {
        onChange(attr);
    }

    return (

        <div className='container curriculum'>
            <div className='row pt-3'>
                <div className='col-8'>

                    <div className='form-group'>

                        <label htmlFor='color'>{t('profile.colors')}</label>

                        <div className='color-selector ps-2 pt-2'>
                            <button type='button' className={`btn btn-red  ${user.color === 0  ? 'active' : ''}`} onClick={() => onChange({ color: 0 })} />
                            <button type='button' className={`btn btn-green ${user.color === 1 ? 'active' : ''}`} onClick={() => onChange({ color: 1 })} />
                            <button type='button' className={`btn btn-blue ${user.color === 2 ? 'active' : ''}`} onClick={() => onChange({ color: 2 })} />
                            <button type='button' className={`btn btn-yellow ${user.color === 3 ? 'active' : ''}`} onClick={() => onChange({ color: 3 })} />
                        </div>

                    </div>

                    <div className='form-group pt-4'>
                        <label htmlFor='availability'>{t('profile.availability')}</label>
                        <input id='availability' type='text' className='form-control' value={user.availability ?? ''} placeholder={t('profile.availability-placeholder')} autoComplete='off' onChange={(e) => handleAttributeChange({ availability: e.target.value })} />
                    </div>

                    <div className='form-group pt-3'>
                        <label htmlFor='biography'>{t('profile.biography')}</label>
                        <textarea id='biography' className='form-control' rows='4' value={user.biography ?? ''} placeholder={t('profile.biography-placeholder')} onChange={(e) => handleAttributeChange({ 'biography': e.target.value })} />
                    </div>

                </div>
            </div>
        </div>

    )
}

export default TabMyCV;
